import { default as _91_91slug_93_93ieni5BieIDMeta } from "/opt/buildhome/repo/frontend/pages/[[slug]].vue?macro=true";
import { default as _91slug_93DDArBwF7UaMeta } from "/opt/buildhome/repo/frontend/pages/categories/[slug].vue?macro=true";
import { default as indexQDqSA4fFW3Meta } from "/opt/buildhome/repo/frontend/pages/categories/index.vue?macro=true";
import { default as _91slug_9355GgqvKpEUMeta } from "/opt/buildhome/repo/frontend/pages/projects/[slug].vue?macro=true";
import { default as indexacV0uUvIVbMeta } from "/opt/buildhome/repo/frontend/pages/projects/index.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug?",
    component: () => import("/opt/buildhome/repo/frontend/pages/[[slug]].vue")
  },
  {
    name: "categories-slug",
    path: "/categories/:slug()",
    component: () => import("/opt/buildhome/repo/frontend/pages/categories/[slug].vue")
  },
  {
    name: "categories",
    path: "/categories",
    component: () => import("/opt/buildhome/repo/frontend/pages/categories/index.vue")
  },
  {
    name: "projects-slug",
    path: "/projects/:slug()",
    component: () => import("/opt/buildhome/repo/frontend/pages/projects/[slug].vue")
  },
  {
    name: "projects",
    path: "/projects",
    component: () => import("/opt/buildhome/repo/frontend/pages/projects/index.vue")
  }
]